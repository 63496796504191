import axios from "axios";
import qs from "qs";
import Vue from "vue";
import store from "../store";
import * as Sentry from "@sentry/browser";

export default () => {
  let Api = axios.create({
    baseURL: "https://api.notif.immo",
    headers: {
      PlatformOrigin: "melo",
      "Content-Type": "application/ld+json",
    },
  });
  Api.interceptors.request.use((config) => {
    store.commit("UPDATE_LOADING", true);
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: "indices",
        encode: false,
        skipNulls: true,
        strictNullHandling: true,
      });
    };
    return config;
  });
  Api.interceptors.response.use(
    (response) => {
      store.commit("UPDATE_LOADING", false);
      return response;
    },
    (error) => {
      store.commit("UPDATE_LOADING", false);
      let stringData = JSON.stringify(error.response.data);
      let parsedData = JSON.parse(stringData);
      /*--------401--------*/
      if (error.response.status === 401 && parsedData.message)
        Sentry.setExtra("errorMessage", parsedData.message);
      /*--------Expired Token--------*/
      if (
        error.response.status === 401 &&
        (parsedData.message == "Expired JWT Token" ||
          parsedData.message == "Invalid JWT Token" ||
          parsedData.message == "JWT Token not found")
      ) {
        store.dispatch("logout");
        Vue.notify({
          group: "global",
          title: "⚠️ Votre session a expiré.<br><br>Veuillez vous reconnecter pour accéder à votre compte.",
        });
      }
      /*--------Email not confirmed--------*/
      if (error.response.status === 401 && parsedData.message == "Account disabled.")
        Vue.notify({
          group: "global",
          title: "⚠️ Veuillez d'abord confirmer votre compte.",
        });
      /*--------Limit of searches reached--------*/
      if (
        error.response.status === 400 &&
        error.response.data["hydra:description"].includes("has reached the limit of")
      ) {
        Vue.notify({
          group: "global",
          title:
            "⚠️ Vous avez atteint le nombre maximum de recherches. Choisissez un plan supérieur pour en créer davantage.",
        });
      }
       /*--------Prevision count > 100--------*/
       if (
        error.response.status === 400 &&
        error.response.data["hydra:description"].includes("The prevision count should be less than")
      ) {
        Vue.notify({
          group: "global",
          title:
            "⚠️ Avec ces critères, vous risquez de recevoir plus de 100 emails / jour. Affinez votre recherche pour continuer.",
        });
      }
      /*--------400--------*/
      if (
        error.response.status === 400 &&
        !error.response.data["hydra:description"].includes("has reached the limit of")
      ) {
        Sentry.setExtra("errorMessage", error.response.data["hydra:description"]);
        Vue.notify({
          group: "global",
          title: error.response.data["hydra:description"],
        });
      }
      /*--------404--------*/
      if (error.response.status === 404 && error.response.data["hydra:description"]) {
        Sentry.setExtra("errorMessage", error.response.data["hydra:description"]);
      }
      return Promise.reject(error);
    }
  );
  return Api;
};
