<template>
<svg width="24px" height="28px" viewBox="0 0 24 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>share</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="share" fill="#000000" fill-rule="nonzero">
            <path d="M18.8318584,17.4639175 C17.6283186,17.4639175 16.4955752,17.8969072 15.6460177,18.6185567 L10.1946903,15.1546392 C10.3362832,14.4329897 10.3362832,13.6391753 10.1946903,12.8453608 L15.6460177,9.3814433 C16.4955752,10.1030928 17.6283186,10.5360825 18.8318584,10.5360825 C21.6637168,10.5360825 24,8.22680412 24,5.26804124 C24,2.30927835 21.7345133,0 18.8318584,0 C15.9292035,0 13.6637168,2.30927835 13.6637168,5.26804124 C13.6637168,5.62886598 13.7345133,6.06185567 13.8053097,6.42268041 L8.3539823,9.88659794 C7.50442478,9.16494845 6.37168142,8.73195876 5.16814159,8.73195876 C2.33628319,8.73195876 0,11.0412371 0,14 C0,16.9587629 2.26548673,19.2680412 5.16814159,19.2680412 C6.37168142,19.2680412 7.50442478,18.8350515 8.3539823,18.1134021 L13.8053097,21.5773196 C13.7345133,21.9381443 13.6637168,22.2989691 13.6637168,22.7319588 C13.6637168,25.6185567 15.9292035,28 18.8318584,28 C21.7345133,28 24,25.6907216 24,22.7319588 C24,19.7731959 21.6637168,17.4639175 18.8318584,17.4639175 Z" id="Path"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
