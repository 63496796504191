<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 30 40"
    style="enable-background: new 0 0 30 40;"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M26.9,6.3L1.8,20.8c-1,0.6-0.9,1.9,0.1,2.3l5.8,2.4l15.6-13.7c0.3-0.3,0.7,0.1,0.5,0.5l-13,15.9v4.4
	c0,1.3,1.5,1.8,2.3,0.9l3.4-4.2l6.7,2.8c0.8,0.3,1.6-0.2,1.8-1l3.9-23.4C29,6.6,27.9,5.8,26.9,6.3z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
