<template>
  <svg
    version="1.1"
    id="filtericon"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24.8 29.8"
    style="enable-background: new 0 0 24.8 29.8;"
    xml:space="preserve"
  >
    <path
      id="Path_93"
      class="st0"
      d="M18.4,6.7c1.6,0.5,2.8,2,2.8,3.7s-1.2,3.2-2.8,3.7v9.8c0,0.6-0.5,1.2-1.2,1.2
	c-0.6,0-1.2-0.5-1.2-1.2V14c-1.6-0.5-2.8-2-2.8-3.7s1.2-3.2,2.8-3.7V5.9c0-0.6,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2V6.7z M15.6,10.4
	c0,0.9,0.7,1.6,1.6,1.7c0.9,0,1.6-0.7,1.7-1.6c0.1-0.9-0.7-1.7-1.6-1.7c0,0,0,0-0.1,0C16.3,8.8,15.6,9.5,15.6,10.4z"
    />
    <path
      id="Path_94"
      class="st0"
      d="M9.4,16.6c2,0.5,3.2,2.6,2.6,4.7c-0.3,1.3-1.4,2.3-2.6,2.6c0,0.6-0.5,1.2-1.2,1.2
	s-1.2-0.5-1.2-1.2c-2-0.5-3.2-2.6-2.6-4.7c0.3-1.3,1.4-2.3,2.6-2.6V5.9c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2V16.6z M6.6,20.2
	c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.5c0-0.9-0.7-1.6-1.5-1.6H8.3C7.3,18.7,6.6,19.3,6.6,20.2z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
