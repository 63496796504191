<template>
<svg width="57px" height="28px" viewBox="0 0 57 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>back</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="back" fill="#000000" fill-rule="nonzero">
            <path d="M17.0662872,19.0919178 L55.4733847,19.0919178 C56.3130231,19.0919178 57,18.4053671 57,17.5662495 L57,10.4464644 C57,9.60734682 56.3130231,8.92079611 55.4733847,8.92079611 L17.0662872,8.92079611 L17.0662872,3.05968723 C17.0662872,0.338912183 13.7713425,-1.02147534 11.8503515,0.898323876 L0.896886508,11.8449936 C-0.298962169,13.0401004 -0.298962169,14.9726135 0.896886508,16.1550064 L11.8503515,27.1016761 C13.7713425,29.0214753 17.0662872,27.6610878 17.0662872,24.9403128 L17.0662872,19.0919178 L17.0662872,19.0919178 Z" id="Path"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
