<template>
<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>plus</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="plus" fill="#BABABA" fill-rule="nonzero">
            <path d="M25.5,11.2 L16.8,11.2 L16.8,2.5 C16.8,1.4 15.9,0.6 14.9,0.6 L13,0.6 C11.9,0.6 11.1,1.5 11.1,2.5 L11.1,11.2 L2.4,11.2 C1.3,11.2 0.5,12.1 0.5,13.1 L0.5,15 C0.5,16.1 1.4,16.9 2.4,16.9 L11.1,16.9 L11.1,25.6 C11.1,26.7 12,27.5 13,27.5 L14.9,27.5 C16,27.5 16.8,26.6 16.8,25.6 L16.8,16.9 L25.5,16.9 C26.6,16.9 27.4,16 27.4,15 L27.4,13.1 C27.5,12 26.6,11.2 25.5,11.2 Z" id="Path"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
