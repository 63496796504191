<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.3 32.3"
    style="enable-background: new 0 0 28.3 32.3;"
    xml:space="preserve"
  >
    <path
      id="bell-solid"
      class="st0"
      d="M14.2,29.8c1.8,0,3.3-1.5,3.3-3.3l0,0h-6.6C10.9,28.4,12.3,29.8,14.2,29.8
	C14.1,29.8,14.2,29.8,14.2,29.8z M25.4,22.1c-1-1.1-2.9-2.7-2.9-7.9c0-3.9-2.8-7.3-6.6-8V5c0-0.9-0.7-1.6-1.6-1.6S12.6,4.1,12.6,5
	v1.1c-3.9,0.7-6.6,4.1-6.6,8c0,5.3-1.9,6.9-2.9,7.9c-0.3,0.4-0.5,0.7-0.5,1.2c0,0.9,0.7,1.6,1.6,1.6l0,0h19.8c0.9,0,1.6-0.7,1.6-1.6
	l0,0C25.8,22.8,25.6,22.5,25.4,22.1z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
