<template>
  <div class="container px-md-0" id="nav-landing">
    <b-navbar class="align-items-center px-md-0">
      <router-link
        class="logo pt-4"
        to="/"
        width="80"
        height="80"
        tag="img"
        alt="melo-logo"
        :src="require('@/assets/logo/melo-blue.svg')"
      >
      </router-link>
      <div v-if="!isLoggedIn" class="my-auto ml-auto px-1 mr-md-0 burger">
        <Burger />
      </div>
      <b-btn v-if="isLoggedIn" to="/search" class="btn-blue ml-auto mr-md-0 my-auto">
        Mon Dashboard
      </b-btn>
    </b-navbar>
  </div>
</template>

<script>
import Burger from "@/components/topbar/Burger.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Burger,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
    }),
  },
};
</script>

<style lang="scss" scoped>
.logo {
  cursor: pointer;
}
</style>
