<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44.406"
    height="31.093"
    viewBox="0 0 44.406 31.093"
  >
    <g id="textIcon" transform="translate(-0.013 0.06)">
      <g id="Layer_1" data-name="Layer 1" transform="translate(0.012 -0.06)">
        <g id="TjhRdy.tif" transform="translate(0 0)">
          <path
            id="Path_95"
            data-name="Path 95"
            d="M39.991,5.518V7.492a1.192,1.192,0,0,0,1.345,1.32h1.54c1.135,0,1.533-.39,1.535-1.505V1.631c0-1.355-.323-1.683-1.661-1.683H19.443a5.153,5.153,0,0,0-.553,0,1.13,1.13,0,0,0-1.1,1.18q-.02,3.208,0,6.417A1.156,1.156,0,0,0,19.072,8.8c.555.015,1.111,0,1.666,0,1.049,0,1.461-.412,1.481-1.449V5.51h6.1v21.1H25.808c-.911,0-1.338.447-1.345,1.37v1.789a1.145,1.145,0,0,0,1.222,1.254q5.43.017,10.859,0a1.143,1.143,0,0,0,1.222-1.254c.017-.555,0-1.111,0-1.666,0-1.093-.395-1.481-1.481-1.5H33.915V5.518ZM13.917,26.609V17.754h3.862v.782c0,.96.432,1.38,1.4,1.389h1.663A1.2,1.2,0,0,0,22.2,18.549v-3.7c0-1.234-.353-1.565-1.584-1.565h-19a4.136,4.136,0,0,0-.553.015,1.125,1.125,0,0,0-1.034,1.17c-.012,1.417-.012,2.836,0,4.245a1.123,1.123,0,0,0,1.15,1.195c.7.032,1.4.032,2.1,0a1.149,1.149,0,0,0,1.165-1.185c.017-.321,0-.647,0-.987h3.88v8.875H6.737a1.138,1.138,0,0,0-1.162,1.177c-.017.676-.022,1.357,0,2.034a1.129,1.129,0,0,0,1.212,1.2q4.317.025,8.638,0a1.149,1.149,0,0,0,1.234-1.246c.017-.637.015-1.273,0-1.91A1.172,1.172,0,0,0,15.38,26.6C14.9,26.609,14.433,26.609,13.917,26.609Z"
            transform="translate(-0.012 0.06)"
            fill="#dedede"
          />
          <path
            id="Path_96"
            data-name="Path 96"
            d="M94.178,5.578H88.1V26.67h2.369c1.079,0,1.468.4,1.481,1.5,0,.555.012,1.111,0,1.666a1.146,1.146,0,0,1-1.222,1.254q-5.43.017-10.859,0a1.142,1.142,0,0,1-1.222-1.254c-.015-.595,0-1.192,0-1.789a1.193,1.193,0,0,1,1.345-1.37H82.5V5.59H76.394V7.431c0,1.037-.42,1.444-1.481,1.449H73.247a1.157,1.157,0,0,1-1.281-1.256q-.022-3.208,0-6.417A1.13,1.13,0,0,1,73.077.027a5.153,5.153,0,0,1,.553,0H96.945c1.338,0,1.661.328,1.661,1.683V7.387c0,1.116-.4,1.5-1.535,1.505h-1.54a1.192,1.192,0,0,1-1.345-1.32V6.585Z"
            transform="translate(-54.199 0)"
            fill="#dedede"
          />
          <path
            id="Path_97"
            data-name="Path 97"
            d="M13.917,67.319h1.456a1.169,1.169,0,0,1,1.278,1.256c0,.637.015,1.273,0,1.91a1.151,1.151,0,0,1-1.234,1.246q-4.317.022-8.638,0a1.132,1.132,0,0,1-1.212-1.2c-.022-.676-.017-1.357,0-2.034a1.138,1.138,0,0,1,1.17-1.182c.267-.015.533,0,.8,0h.772V58.452H4.435c0,.346.015.671,0,.987A1.14,1.14,0,0,1,3.27,60.624c-.711.03-1.4.03-2.1,0a1.123,1.123,0,0,1-1.153-1.2c-.012-1.409-.012-2.828,0-4.245a1.125,1.125,0,0,1,1.044-1.167,4.136,4.136,0,0,1,.553-.017h19c1.234,0,1.592.355,1.594,1.565s0,2.468,0,3.7a1.2,1.2,0,0,1-1.352,1.377H19.2c-.97,0-1.389-.429-1.4-1.389v-.782H13.917Z"
            transform="translate(-0.012 -40.65)"
            fill="#dedede"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
