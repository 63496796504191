<template>
  <div>
    <b-navbar class="px-2 px-md-4 pb-2 align-items-start fixed-top">
      <b-button
        class="my-auto py-2 btn-filter"
        to="/search"
        v-if="$route.path === '/settings'"
      >
        <IconBack />
      </b-button>
      <b-button
        @click="showAds"
        v-b-tooltip.hover
        v-if="isFavoritesView"
        title="Retour aux résultats de la recherche"
        class="px-3"
      >
        <IconBack />
      </b-button>
      <div
        class="filters-wrapper"
        v-if="$route.path == '/search' && !isFavoritesView"
      >
        <b-button
          class="btn-filter"
          @click="setView('expressions'), toggleFilter()"
        >
          <IconText />
        </b-button>
        <b-button
          class="btn-filter"
          @click="setView('notifications'), toggleFilter()"
        >
          <IconNotifications />
        </b-button>
        <b-button
          class="btn-filter"
          @click="setView('advanced-filters'), toggleFilter()"
        >
          <IconAdvancedFilters />
        </b-button>
      </div>
      <div class="my-auto ml-auto px-1 mr-md-0 burger">
        <Burger />
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import Burger from "@/components/topbar/Burger.vue";
import IconAdvancedFilters from "@/components/icons/IconAdvancedFilters.vue";
import IconNotifications from "@/components/icons/IconNotifications.vue";
import IconBack from "@/components/icons/IconBack.vue";
import IconText from "@/components/icons/IconText.vue";

export default {
  components: {
    Burger,
    IconAdvancedFilters,
    IconNotifications,
    IconBack,
    IconText,
  },

  methods: {
    ...mapMutations({
      toggleFilter: "TOGGLE_FILTER",
      setView: "SET_CURRENT_VIEW",
    }),
    showAds() {
      this.$store.commit("update", {
        property: "isFavoritesView",
        newValue: false,
      });
    },
  },
  computed: {
    ...mapGetters(["isViewActive"]),
    ...mapState(["isFavoritesView"]),
  },
};
</script>

<style lang="scss" scoped>
.filters-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include media-breakpoint-up(md) {
    flex-wrap: unset;
    justify-content: unset;
  }
}
.navbar {
  background-color: $bg-grey;
  box-shadow: $box-shadow;
}

.btn-filter, .btn-filter:focus, .btn-filter:hover {
  margin-right: 0.5rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  @include media-breakpoint-up(md) {
    margin-bottom: unset;
    margin-right: 1rem;
  }
}
</style>
