<template>
  <div class="sidebar">
    <div
      class="sidebar-backdrop"
      @click="closeSidebarPanel"
      v-if="isPanelOpen"
    ></div>
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel d-flex flex-column">
        <b-img
          alt="melo-icon"
          class="logo"
          :src="require('@/assets/logo/melo-white.svg')"
        ></b-img>
        <hr />
        <ul class="sidebar-panel-nav w-100" v-if="!isLoggedIn">
          <b-nav-item @click="closeNav" to="/login/"
            ><IconPaperplane />Connexion</b-nav-item
          >

          <b-nav-item @click="closeNav" to="/register/">
            <IconPlus />Inscription</b-nav-item
          >

          <b-nav-item @click="closeNav" to="/pricing/">
            <IconCreditCard />Tarifs</b-nav-item
          >

          <b-nav-item @click="closeNav" to="/affiliation/">
            <IconShare />Affiliation</b-nav-item
          >

          <b-nav-item @click="closeNav" to="/api/">
            <IconGlobe />API</b-nav-item
          >

       
        </ul>
        <ul class="sidebar-panel-nav w-100" v-if="isLoggedIn">
          <b-nav-item @click="closeNav" to="/search"
            ><IconSearchResults />Dashboard</b-nav-item
          >

          <b-nav-item @click="closeNav" to="/affiliation/">
            <IconShare />Affiliation
          </b-nav-item>

          <b-nav-item @click="closeNav" to="/settings?apikeys">
            <IconData />API</b-nav-item
          >

        

          <b-nav-item @click="closeNav" to="/settings">
            <IconSettings />Paramètres
          </b-nav-item>
        </ul>

        <div
          class="d-flex align-items-center mt-md-auto mx-auto"
          v-if="isLoggedIn"
        >
          <b-nav-item @click="logout" v-b-tooltip.hover title="Se déconnecter">
            <b-img
              alt="melo-icon"
              class="content-icon"
              :src="require('@/assets/icons/power-off-white.svg')"
            ></b-img>
          </b-nav-item>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import IconSearchResults from "@/components/icons/IconSearchResults.vue";
import IconShare from "@/components/icons/IconShare.vue";
import IconGlobe from "@/components/icons/IconGlobe.vue";
import IconData from "@/components/icons/IconData.vue";
import IconCreditCard from "@/components/icons/IconCreditCard.vue";
import IconSettings from "@/components/icons/IconSettings.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconPaperplane from "@/components/icons/IconPaperplane.vue";

export default {
  components: {
    IconSearchResults,
    IconShare,
    IconGlobe,
    IconData,
    IconSettings,
    IconCreditCard,
    IconPlus,
    IconPaperplane,
  },
  methods: {
    ...mapMutations({
      closeSidebarPanel: "TOGGLE_NAV",
      closeNav: "CLOSE_NAV",
    }),
    ...mapActions({
      logout: "logout",
    }),
  },
  computed: {
    ...mapGetters({
      isPanelOpen: "isNavOpen",
      isLoggedIn: "isLoggedIn",
    }),
  },
};
</script>
<style lang="scss" scoped>
a,
a:hover {
  color: white;
  width: 100%;
}
.logo {
  margin: 0 auto;
}
hr {
  border: 1px solid $primary-light;
  width: 100%;
  margin-top: 1rem;
}
ul {
  padding: 1rem;
}
li {
  display: flex;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: $radius;
  align-items: center;
  &:hover {
    background-color: $primary-light;
  }
}
.nav-link {
  svg {
    margin-right: 1rem;
  }
}
::v-deep {
  svg {
    width: 1.5rem;
    path {
      fill: white;
    }
  }
}
</style>
<style lang="scss">
.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 998;
}

.sidebar-panel {
  color: white;
  overflow-y: auto;
  background-color: $blue;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding-top: 1rem;
  width: 300px;
  font-size: 1.3rem;
  line-height: 3rem;
}

.logo {
  margin-bottom: 2rem;
}
.nav-link {
  font-size: 1rem;
}
.nav-link.disabled {
  color: unset !important;
}
</style>
