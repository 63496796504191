<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="42.56" height="44.459" viewBox="0 0 42.56 44.459">
  <path id="settings" d="M60.66,35.671l-3.818-2.205a17.266,17.266,0,0,0,0-6.292l3.818-2.205a1.083,1.083,0,0,0,.493-1.255,22.324,22.324,0,0,0-4.9-8.479,1.079,1.079,0,0,0-1.327-.206l-3.818,2.205a16.916,16.916,0,0,0-5.45-3.146v-4.4a1.074,1.074,0,0,0-.843-1.049,22.533,22.533,0,0,0-9.788,0,1.074,1.074,0,0,0-.843,1.049V14.1a17.45,17.45,0,0,0-5.45,3.146l-3.809-2.205a1.065,1.065,0,0,0-1.327.206,22.19,22.19,0,0,0-4.9,8.479,1.072,1.072,0,0,0,.493,1.255l3.818,2.205a17.266,17.266,0,0,0,0,6.292L19.187,35.68a1.083,1.083,0,0,0-.493,1.255,22.324,22.324,0,0,0,4.9,8.479,1.079,1.079,0,0,0,1.327.206l3.818-2.205a16.916,16.916,0,0,0,5.45,3.146v4.41a1.074,1.074,0,0,0,.843,1.049,22.533,22.533,0,0,0,9.788,0,1.074,1.074,0,0,0,.843-1.049v-4.41a17.45,17.45,0,0,0,5.45-3.146l3.818,2.205a1.065,1.065,0,0,0,1.327-.206,22.19,22.19,0,0,0,4.9-8.479A1.107,1.107,0,0,0,60.66,35.671ZM39.919,37.49a7.171,7.171,0,1,1,7.171-7.171A7.18,7.18,0,0,1,39.919,37.49Z" transform="translate(-18.644 -8.099)" fill="#fff"/>
</svg>

</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>

