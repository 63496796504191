<template>
<svg width="36px" height="32px" viewBox="0 0 36 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>data</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="data" fill="#000000" fill-rule="nonzero">
            <path d="M36,15.8488092 C36,15.5640707 35.7308696,15.1654369 35.2053693,15.0720427 C34.8303062,15.0271666 34.4500254,15.0896581 34.1102869,15.2519974 C28.892405,17.6665796 23.6818701,20.0963479 18.4786821,22.5413022 C18.1772364,22.7046551 17.8114904,22.7046551 17.5100446,22.5413022 C12.3401113,20.1160897 7.16553774,17.7003686 1.98632402,15.2941387 C1.79922929,15.199253 1.6053815,15.1177946 1.40630158,15.0504025 C1.05020485,14.9384671 0.660460614,15.0157513 0.37652293,15.2546021 C0.0925852464,15.4934529 -0.0449654729,15.8597338 0.0130876804,16.2223861 C0.0908106875,16.8009746 0.537427967,17.0196538 0.99912583,17.236055 C6.4002948,19.755421 11.8003037,22.2782038 17.1991526,24.8044035 C17.6976641,25.0651988 18.2957028,25.0651988 18.7942143,24.8044035 C21.2604697,23.6358368 23.7394856,22.4911882 26.2185016,21.336289 C29.1797095,19.953599 32.141304,18.5705293 35.1032853,17.18708 C35.5731035,16.966123 35.974479,16.6950519 36,15.8488092 Z" id="Path"></path>
            <path d="M18.1223536,0 C23.783003,2.64532461 29.4440391,5.28950801 35.1054619,7.9325502 C35.6066371,8.16079219 36.0045608,8.45978919 35.9999605,9.06691287 C35.9952798,9.67403655 35.5718332,9.95020935 35.0718182,10.1807338 C29.6331399,12.7187846 24.1963952,15.2621612 18.761584,17.8108633 C18.2804388,18.0630456 17.7032397,18.0630456 17.2220946,17.8108633 C11.7896036,15.2553139 6.354019,12.7111766 0.915340696,10.1784513 C0.418806073,9.94678572 0,9.67175413 0,9.05778319 C0,8.44381225 0.402564286,8.1619334 0.902579292,7.92798536 C6.37606143,5.37167513 11.8479967,2.81232166 17.3183852,0.249924974 C17.507486,0.16205181 17.7000672,0.0833083247 17.8903281,0 L18.1223536,0 Z M32.2921524,9.06463045 C32.0902902,8.95735672 31.9441142,8.87176597 31.7909773,8.79986975 C27.3631569,6.72971494 22.9361099,4.65803852 18.5098363,2.58484048 C18.1922111,2.4120042 17.8065491,2.4120042 17.4889239,2.58484048 C13.0665174,4.66564658 8.63947042,6.737323 4.2077829,8.79986975 C4.05580618,8.87062476 3.9096301,8.95735672 3.73561095,9.05093593 C3.82726104,9.10685522 3.87366614,9.14109151 3.92587188,9.16505692 C8.50992284,11.3082492 13.0962941,13.447257 17.6849855,15.5820803 C17.9084937,15.6587852 18.1533502,15.6485792 18.3694608,15.5535501 C19.8950286,14.8688241 21.4101553,14.1521443 22.9264421,13.4434529 C26.0286234,11.9952575 29.1296445,10.5436385 32.2921524,9.06463045 Z" id="Shape"></path>
            <path d="M17.6548106,32 C16.3073833,31.3583152 14.9645943,30.7064449 13.6113691,30.0760774 C9.44849133,28.141592 5.28406743,26.210879 1.11809743,24.2839385 C0.906800958,24.1935217 0.703229757,24.0868018 0.509320041,23.9647937 C0.0506647071,23.68072 -0.12588633,23.1118091 0.0941918373,22.6271016 C0.34094025,22.1315007 0.924760361,21.8898574 1.46133193,22.061242 C1.64212335,22.1252505 1.81837734,22.2008636 1.988939,22.2875858 C7.16451316,24.6883395 12.3381547,27.0906021 17.5098636,29.4943737 C17.8114678,29.6556236 18.1765053,29.6556236 18.4781095,29.4943737 C23.6490454,27.0800394 28.8226869,24.6770223 33.9990341,22.2853224 C34.1567365,22.2128923 34.3098005,22.1314085 34.4733007,22.0748226 C35.0207049,21.8669907 35.6397567,22.1057036 35.8937813,22.6225747 C36.1256965,23.0877113 35.9726325,23.6320682 35.4856106,23.9580033 C35.2747184,24.0898137 35.0537467,24.2055908 34.8246522,24.3043094 C29.4403544,26.800882 24.0560566,29.2974545 18.6717587,31.7940271 C18.5598756,31.8552499 18.4525432,31.9240774 18.3505562,32 L17.6548106,32 Z" id="Path"></path>
            <path d="M36,15.8506536 C35.9744755,16.6967257 35.5731037,16.9677421 35.0986496,17.1886547 C32.1359221,18.5695477 29.1743545,19.9523386 26.213947,21.3370274 C23.7395939,22.4916938 21.2606006,23.6361118 18.7897275,24.8044429 C18.2912206,25.0651857 17.6931873,25.0651857 17.1946804,24.8044429 C11.8020676,22.2726792 6.40365473,19.751164 0.99944164,17.2398973 C0.537747991,17.0212622 0.0911347893,16.8049046 0.0134124918,16.2241552 C-0.045386027,15.8614793 0.0916347704,15.4948169 0.375398193,15.2554955 C0.659161615,15.0161741 1.04906381,14.9384381 1.40545364,15.0501304 C1.60453174,15.1175088 1.79837776,15.1989508 1.98547079,15.2938174 C7.1615438,17.7056355 12.3360701,20.1204902 17.5090497,22.5383815 C17.8104927,22.7017015 18.1762353,22.7017015 18.4776783,22.5383815 C23.6823655,20.0969566 28.8928529,17.667678 34.1091404,15.2505459 C34.4488758,15.0882393 34.8291532,15.0257604 35.2042128,15.0706274 C35.7308683,15.1628641 36.0011563,15.5659725 36,15.8506536 Z" id="Path"></path>
        </g>
    </g>
</svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
