<template>
  <div id="app">
   <!--  <div class="bandeau py-3 text-center bg-blue text-white w-100">Mise à jour en cours - Veuillez nous excuser pour la gêne occasionnée.</div> -->
   <div v-if="$route.name === 'landing' || $route.name === 'register' || $route.name === 'login'" class="bandeau py-3 text-center bg-blue text-white w-100">Nouveauté - Générez des clés API directement depuis les paramètres de votre compte</div> 
    <notifications
      group="global"
      position="top right"
      animation-type="css"
      animation-name="fade"
      :speed="500"
      width="300"
    />
    <div id="topbar">
      <landing-nav
        v-if="
          $route.name === 'landing' ||
          $route.name === 'register' ||
          $route.name === '404' ||
          $route.name === 'expired' ||
          $route.name === 'naas' ||
          $route.name === 'property-tax' ||
          $route.name === 'data' ||
          $route.name === 'thanks' ||
          $route.name === 'reset-success' ||
          $route.name === 'pricing' ||
          $route.name === 'opportunities' ||
          $route.name === 'email-confirmed' ||
          $route.name === 'email-sender-confirmed' ||
          $route.name === 'login' ||
          $route.name === 'forgot' ||
          $route.name === 'api' ||
          $route.name === 'reset-password' ||
          $route.name === 'terms' ||
          $route.name === 'privacy' ||
          $route.name === 'affiliation' ||
          $route.name === 'thanks-signup' ||
          $route.name === 'thanks-retarget' ||
          $route.path.includes('blog')
        "
      />
      <search-filters v-if="$route.path === '/search'" />
    </div>
    <div id="sidebar">
      <Sidebar />
    </div>
    <div id="content">
      <router-view />
    </div>    
    <Spinner
      class="loading"
      v-if="loading"
      :size="55"
      :line-size="7"
      line-fg-color="#C5C5C5"
      :speed="0.6"
    ></Spinner>
  </div>
</template>

<script>
import SearchFilters from "@/components/topbar/SearchFilters.vue";
import LandingNav from "@/components/topbar/LandingNav.vue";
import Sidebar from "@/components/sidebar/Sidebar.vue";
import Spinner from "vue-simple-spinner";
import { mapState } from "vuex";
import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

Sentry.init({
  dsn:
    "https://e5b0d67f97c6410ba38b87fb12b503ac@o389137.ingest.sentry.io/5226918",
  environment: "prod",
  integrations: [new VueIntegration({ Vue, attachProps: true })],
});

/***** MELO-TEST *****/
/* Sentry.init({
  dsn: "https://aa89762ee49a4b41b521bc7a5cf896de@o389137.ingest.sentry.io/5423263",
  environment: "dev",
  integrations: [new VueIntegration({ Vue, attachProps: true })],
}); */

export default {
  components: {
    SearchFilters,
    Sidebar,
    LandingNav,
    Spinner,
  },
 
  computed: {
    ...mapState(["loading", "userData"]),
  },
  
  mounted() {
    let params = new URLSearchParams(window.location.search);
    if (params.get("utm_campaign"))
      this.$store.commit("SET_UTM_CAMPAIGN", params.get("utm_campaign"));
    if (params.get("utm_medium"))
      this.$store.commit("SET_UTM_MEDIUM", params.get("utm_medium"));
    if (params.get("utm_content"))
      this.$store.commit("SET_UTM_CONTENT", params.get("utm_content"));
    if (params.get("utm_source"))
      this.$store.commit("SET_UTM_SOURCE", params.get("utm_source"));
    
    const style3 = [
      "color: white",
      "text-shadow: 2px 2px black",
      "background: #3d5afe",
      "font-size: 3em",
      "padding: 20px",
      "font-family: Avenir",
    ].join(";");
    console.log("%cWant real estate data in real time ?", style3);
    console.log("%cWe have an awesome API", style3);
    console.log("%cGenerate API Keys in your settings and start automating!", style3);
    window.$chatwoot.setLocale("fr");
  },
};
</script>

<style lang="scss">
@import "@/styles/fonts.scss";

#app {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $body-text;
  font-size: 14px;
}
</style>
