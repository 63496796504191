<template>
  <svg
    version="1.1"
    id="Layer_1"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 576 512"
    style="enable-background: new 0 0 576 512;"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M4.7,429.2c0,25.8,21.5,47.3,47.3,47.3h471.2c25.8,0,47.3-21.5,47.3-47.3V257.1H4.7V429.2z M194.1,362.5
	c0-6.5,4.3-10.8,10.8-10.8h133.4c6.5,0,10.8,4.3,10.8,10.8v38.7c0,6.5-4.3,10.8-10.8,10.8H204.9c-6.5,0-10.8-4.3-10.8-10.8V362.5z
	 M67.1,362.5c0-6.5,4.3-10.8,10.8-10.8h71c6.5,0,10.8,4.3,10.8,10.8v38.7c0,6.5-4.3,10.8-10.8,10.8H80.1c-6.5,0-10.8-4.3-10.8-10.8
	v-38.7H67.1z M570.7,82.8v47.3H4.7V82.8c0-25.8,21.5-47.3,47.3-47.3h471.2C549.1,35.4,570.7,57,570.7,82.8z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
